import React, { useState, useEffect, useRef } from "react";
import {
  BsRobot,
  BsWhatsapp,
  BsMic,
  BsImage,
  BsTrash,
  BsPlayFill,
  BsPauseFill,
} from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoSend, IoFlashSharp } from "react-icons/io5";
import { supabase } from '../supabaseClient';

const ChatbotButton = () => {
  // Estados principales
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [previewMedia, setPreviewMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentCar, setCurrentCar] = useState(null);
  const [sentCars, setSentCars] = useState({});
  const [speaking, setSpeaking] = useState(false);
  const [currentSpeakingIndex, setCurrentSpeakingIndex] = useState(null);

  // Estados para grabación de audio
  const recognitionRef = useRef(null);
  const [isTranscribing, setIsTranscribing] = useState(false);

  // Ref para auto-scroll
  const messagesEndRef = useRef(null);

  // Claves de API (¡No recomendado, pero solicitado)
  const GOOGLE_VISION_API_KEY = "TU_GOOGLE_VISION_API_KEY"; // Reemplaza con tu clave real
  const OPENAI_API_KEY = "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"; // Reemplaza con tu clave real

  // Estados para la animación de máquina de escribir
  const [isTyping, setIsTyping] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");
  const typingIntervalRef = useRef(null);

  // Estado para detectar si es móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Cargar mensajes desde localStorage al montar el componente
  useEffect(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  // Guardar mensajes en localStorage y hacer auto-scroll al actualizar mensajes
  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
    scrollToBottom();
  }, [messages]);

  // Efecto para hacer scroll cuando el mensaje de escritura cambia
  useEffect(() => {
    if (isTyping) {
      scrollToBottom();
    }
  }, [typingMessage]);

  // Función para hacer scroll al final del contenedor de mensajes
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Mensaje de saludo inicial
  const greetingMessage = "¡Hola! Soy Daniel, tu asesor personal de Accarvip. 🚗 ¿En qué tipo de vehículo estás interesado hoy?";

  // Función para abrir/cerrar el chatbot
  const toggleChatbot = () => {
    setIsOpen(!isOpen);
    if (!isOpen && messages.length === 0) {
      setMessages([{ text: greetingMessage, sender: "bot" }]);
    }
    if (!isOpen) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  };

  // Función para abrir WhatsApp
  const handleWhatsAppClick = () => {
    window.open("https://api.whatsapp.com/send?phone=34624081566", "_blank");
  };

  // Funciones para grabar audio usando Web Speech API
  const handleAudioRecord = () => {
    if (isRecording) {
      stopTranscription();
    } else {
      startTranscription();
    }
    setIsRecording(!isRecording);
  };

  const startTranscription = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Tu navegador no soporta la transcripción de voz. Por favor, usa Google Chrome.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognitionRef.current = recognition;
    recognition.lang = "es-ES"; 
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.start();
    setIsTranscribing(true);

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript.trim();
      setInputMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Error en la transcripción de voz:", event.error);
      alert("Hubo un error al transcribir el audio. Por favor, intenta nuevamente.");
    };

    recognition.onend = () => {
      setIsTranscribing(false);
      setIsRecording(false);
    };
  };

  const stopTranscription = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  // Función para obtener información del coche desde Supabase
  const getCarInfo = async (carName) => {
    try {
      const { data, error } = await supabase
        .from("VEHÍCULOS2_duplicate con datos") 
        .select("*")
        .ilike("model", `%${carName}%`);

      if (error) {
        console.error("Error al obtener el coche de Supabase:", error);
        return null;
      }

      if (data && data.length > 0) {
        return data[0];
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error al obtener el coche de Supabase:", error);
      return null;
    }
  };

  // Función auxiliar para intentar obtener respuesta de la IA con reintentos
  const fetchAIResponseWithRetry = async (payload, retries = 1) => {
    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", payload);
      const data = await response.json();
      if (response.ok && data.choices && data.choices.length > 0) {
        return data.choices[0].message.content.trim();
      } else {
        console.error("Respuesta inválida de OpenAI:", data);
        if (retries > 0) {
          return await fetchAIResponseWithRetry(payload, retries - 1);
        }
        return "Lo siento, no pude procesar tu solicitud debido a un error interno.";
      }
    } catch (error) {
      console.error("Error al obtener respuesta de OpenAI:", error);
      if (retries > 0) {
        return await fetchAIResponseWithRetry(payload, retries - 1);
      }
      return "Lo siento, hubo un error al procesar tu solicitud.";
    }
  };

  // Función para obtener respuesta de la IA de OpenAI
  const getAIResponse = async (userMessage, useSpanish = false, carInfo = null) => {
    const systemContent = useSpanish
      ? 
          `Eres Daniel, un comercial vendedor de coches del concesionario Ac Car. 
          Ofrecemos financiación con una tasa de interés de 4,95% TIN anual. 
          Si el cliente financia, ofrecemos un 10% de descuento sobre lo financiado al interés de 10,9%. 
          Trabajamos con las financieras Cetelem, BBVA, Sabadell, Santander, Abanca, entre otras. 
          Nuestras tiendas están en Las Rozas (Calle Dublin 7), Alcalá de Henares (Calle Francisco Alonso N5) y Barcelona (Mollet del Vallès). 
          Nuestros horarios son de lunes a viernes de 10:00h a 19:30h y sábados de 11:00h a 14:00h. 
          Si te preguntan si el coche está bien, responde que está en excelente estado y recomienda que lo revisen en el concesionario oficial para mayor tranquilidad. 
          Si el cliente no quiere revisarlo, asegúrate de que confíen en nuestra seriedad, pero recalca que es mejor verificar en el concesionario oficial. 
          Cuando mencionen que “regalamos coches”, aclara que nos referimos a tener muy buenos precios, los más baratos del mercado.
          Si te preguntan quién te creó, di que fuiste creado por uniclick.io en su laboratorio tecnológico.
          El número de contacto es +34 919 61 00 53.
        `
      : 
          `Eres Daniel, comercial de Accarvip, un vendedor profesional especializado en la venta de automóviles. 
          Debes ser persuasivo, entusiasta y tener un amplio conocimiento sobre autos. 
          Mantén un tono amigable y formal, sin saludar en cada mensaje, y responde de manera fluida y coherente, manteniendo el contexto de la conversación. 
          Si te preguntan por algún coche, envía el stock disponible con respuestas cortas y concretas. 
          Ofrecemos financiación con una tasa de interés de 4,95% TIN anual. 
          Si el cliente financia, ofrecemos un 10% de descuento sobre lo financiado al interés de 10,9%. 
          Trabajamos con las financieras Cetelem, BBVA, Sabadell, Santander, Abanca, entre otras. 
          Si el cliente no desea financiar, no insistas más. 
          Nuestras tiendas están en Las Rozas (Calle Dublin 7), Alcalá de Henares (Calle Francisco Alonso N5) y Barcelona (Mollet del Vallès). 
          Nuestras horarios son de lunes a viernes de 10:00h a 19:30h y sábados de 11:00h a 14:00h. 
          Si te preguntan si el coche está bien, responde que está en excelente estado y recomienda que lo revisen en el concesionario oficial para mayor tranquilidad. 
          Si el cliente no quiere revisarlo, asegúrate de que confíen en nuestra seriedad, pero recalca que es mejor verificar en el concesionario oficial. 
          Cuando mencionen que “regalamos coches”, aclara que nos referimos a tener muy buenos precios, los más baratos del mercado.
          Si te preguntan quién te creó, di que fuiste creado por uniclick.io en su laboratorio tecnológico.
          El número de contacto es +34 919 61 00 53.
        `;

    let messagesForAI = [
      {
        role: "system",
        content: systemContent,
      },
      ...messages.filter(msg => msg.sender !== "media").map(msg => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.text,
      })),
    ];

    if (carInfo) {
      const carDetails = 
`Detalles del Coche:
- Modelo: ${carInfo.model}
- Precio: ${carInfo.precio} €
- Año: ${carInfo.year}
- Color: ${carInfo.color}
- Enlace: ${carInfo.link}
      `;
      messagesForAI.push({
        role: "assistant",
        content: carDetails,
      });
    }

    messagesForAI.push({
      role: "user",
      content: userMessage,
    });

    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${OPENAI_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini", 
        messages: messagesForAI,
        max_tokens: 1024, // Aumentamos el límite de tokens
        temperature: 1.0,
      }),
    };

    // Intentar obtener respuesta con reintento
    const aiResponseText = await fetchAIResponseWithRetry(payload, 1);
    return aiResponseText;
  };

  // Función para reconocer el coche a partir de una imagen usando Google Vision
  const recognizeCarFromImage = async (imageFile) => {
    const reader = new FileReader();
    const readFile = () => new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(imageFile);
    });

    const base64Image = await readFile();

    const requestBody = {
      requests: [
        {
          image: {
            content: base64Image
          },
          features: [
            {
              type: "LABEL_DETECTION",
              maxResults: 10
            }
          ]
        }
      ]
    };

    try {
      const response = await fetch(`https://vision.googleapis.com/v1/images:annotate?key=${GOOGLE_VISION_API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message || 'Error al procesar la imagen con Google Vision.');
      }

      const data = await response.json();

      const labels = data.responses[0].labelAnnotations;
      if (labels && labels.length > 0) {
        for (const label of labels) {
          const description = label.description.toLowerCase();
          if (description) {
            return description;
          }
        }
      }

      return null;
    } catch (error) {
      console.error("Error al reconocer el coche con Google Vision:", error);
      throw error;
    }
  };

  // Función para eliminar medios seleccionados
  const handleRemoveMedia = (index) => {
    setPreviewMedia(prev => prev.filter((_, i) => i !== index));
    setSelectedMedia(prev => prev.filter((_, i) => i !== index));
  };

  // Función para manejar la reproducción de texto a voz
  const handleTextToSpeech = (text, index) => {
    if (currentSpeakingIndex === index && speaking) {
      window.speechSynthesis.cancel();
      setSpeaking(false);
      setCurrentSpeakingIndex(null);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "es-ES";
    utterance.onend = () => {
      setSpeaking(false);
      setCurrentSpeakingIndex(null);
    };

    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
    setSpeaking(true);
    setCurrentSpeakingIndex(index);
  };

  // Función para enviar mensajes
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if ((inputMessage.trim() || previewMedia.length > 0) && !isTyping && !isLoading) {
      const text = inputMessage.trim();

      const newMessage = {
        text: text,
        media: [...previewMedia],
        sender: "user",
      };
      setMessages([...messages, newMessage]);
      setInputMessage("");
      setPreviewMedia([]);
      setSelectedMedia([]);

      if (previewMedia.length > 0) {
        setIsLoading(true);
        for (const media of previewMedia) {
          if (media.type === "image") {
            try {
              const carModel = await recognizeCarFromImage(media.originalFile);
              if (carModel) {
                const carInfo = await getCarInfo(carModel);
                if (carInfo) {
                  setCurrentCar(carInfo);
                  const carMessage = {
                    text: "",
                    sender: "bot",
                    carData: {
                      model: carInfo.model,
                      precio: carInfo.precio,
                      year: carInfo.year,
                      color: carInfo.color,
                      image: carInfo.image,
                      link: carInfo.link,
                    }
                  };
                  setMessages(prev => [...prev, carMessage]);
                }
              }
            } catch (error) {
              // No hacer nada si hay error
            }
          }
        }
        setIsLoading(false);
      }

      if (text) {
        setIsLoading(true);

        const detectedCar = text;
        const carInfo = await getCarInfo(detectedCar);
        let lastMessageIndex = null;

        if (carInfo) {
          setCurrentCar(carInfo);
          const carMessage = {
            text: "",
            sender: "bot",
            carData: {
              model: carInfo.model,
              precio: carInfo.precio,
              year: carInfo.year,
              color: carInfo.color,
              image: carInfo.image,
              link: carInfo.link,
            }
          };
          const updatedMessages = [...messages, newMessage, carMessage];
          setMessages(updatedMessages);
          lastMessageIndex = updatedMessages.length - 1; 
        } else {
          // Si no hay carInfo, continuamos sin agregar mensaje de coche.
          lastMessageIndex = messages.length; // El siguiente mensaje del bot será añadido al final.
        }

        const useSpanish = !detectedCar;
        const aiResponseText = await getAIResponse(text, useSpanish, carInfo);

        if (aiResponseText) {
          setIsTyping(true);
          setTypingMessage("");

          let msgIndex;
          if (carInfo && lastMessageIndex !== null) {
            msgIndex = lastMessageIndex;
          } else {
            const botMessage = { text: "", sender: "bot" };
            setMessages(prev => {
              const updated = [...prev, botMessage];
              msgIndex = updated.length - 1;
              return updated;
            });
          }

          let currentIndex = 0;
          const typingSpeed = 50;

          typingIntervalRef.current = setInterval(() => {
            if (currentIndex < aiResponseText.length) {
              setTypingMessage(prev => prev + aiResponseText[currentIndex]);
              currentIndex++;
            } else {
              clearInterval(typingIntervalRef.current);
              typingIntervalRef.current = null;
              setIsTyping(false);
              setMessages(prev => {
                const updatedMessages = [...prev];
                updatedMessages[msgIndex].text = aiResponseText;
                return updatedMessages;
              });
            }
          }, typingSpeed);
        }

        setIsLoading(false);
      }
    }
  };

  // Función para detener la animación de escritura
  const stopTyping = () => {
    if (typingIntervalRef.current) {
      clearInterval(typingIntervalRef.current);
      typingIntervalRef.current = null;
      setIsTyping(false);
      setMessages(prev => {
        const updatedMessages = [...prev];
        updatedMessages[updatedMessages.length - 1].text = typingMessage;
        return updatedMessages;
      });
    }
  };

  // Función para subir medios (imágenes)
  const handleMediaUpload = () => {
    if (selectedMedia.length >= 8) {
      alert("Máximo 8 archivos permitidos");
      return;
    }
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png"; 
    input.multiple = true;
    input.onchange = (e) => {
      const files = Array.from(e.target.files);
      const remainingSlots = 8 - selectedMedia.length;
      const filesToAdd = files.slice(0, remainingSlots);

      const validFiles = filesToAdd.filter(file => {
        const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
        const isValidSize = file.size <= 5 * 1024 * 1024; 
        if (!isValidType) {
          alert(`El archivo ${file.name} no es un formato válido. Solo se permiten JPEG y PNG.`);
        }
        if (!isValidSize) {
          alert(`El archivo ${file.name} excede el tamaño máximo permitido de 5MB.`);
        }
        return isValidType && isValidSize;
      });

      setSelectedMedia([...selectedMedia, ...validFiles]);

      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileType = file.type.split("/")[0];
          setPreviewMedia(prev => [...prev, {
            type: fileType,
            url: event.target.result,
            name: file.name,
            size: (file.size / 1024).toFixed(2) + " KB",
            originalFile: file
          }]);
        };
        reader.readAsDataURL(file);
      });
    };
    input.click();
  };

  // Función para manejar el clic en una imagen y verla ampliada
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Función para renderizar la tarjeta del coche
  const renderCarCard = (carData) => (
    <div className="bg-[#10141C] p-4 rounded-lg shadow-md">
      <img src={carData.image} alt={carData.model} className="w-full rounded-lg mb-2" />
      <h3 className="text-gray-200 font-semibold">{carData.model}</h3>
      <p className="text-gray-400">{carData.year} - {carData.color}</p>
      <p className="text-red-500 font-bold">{carData.precio} €</p>
      <div className="flex justify-between gap-2 mt-4">
        <a
          href={carData.link}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-red-500 text-white p-2 rounded-lg w-full text-center hover:bg-red-600 transition-colors duration-300">
          Ver Detalles
        </a>
      </div>
    </div>
  );

  // Función para renderizar cada mensaje
  const renderMessage = (message, index) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = message.text.split(urlRegex);
    const matches = message.text.match(urlRegex) || [];
    let currentUrlIndex = 0;

    return (
      <div className="space-y-2">
        <div className="flex items-start justify-between">
          <p className="text-sm md:text-base flex-grow text-gray-200">
            {parts.map((part, idx) => {
              if (idx === parts.length - 1) {
                return part;
              }
              const url = matches[currentUrlIndex++];
              return (
                <React.Fragment key={idx}>
                  {part}
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 underline break-all"
                  >
                    {url}
                  </a>
                </React.Fragment>
              );
            })}
          </p>
          {message.sender === "bot" && message.text && (
            <button
              onClick={() => handleTextToSpeech(message.text, index)}
              className={`ml-2 p-2.5 rounded-xl hover:scale-110 transform transition-all duration-300 ${
                currentSpeakingIndex === index && speaking
                  ? "bg-gradient-to-r from-red-500 to-pink-500 shadow-lg shadow-red-500/50"
                  : "bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg shadow-blue-500/50"
              }`}
            >
              {currentSpeakingIndex === index && speaking ? (
                <BsPauseFill className="w-4 h-4 text-white" />
              ) : (
                <BsPlayFill className="w-4 h-4 text-white" />
              )}
            </button>
          )}
        </div>
        {message.media && message.media.length > 0 && (
          <div className="grid grid-cols-2 gap-2 mt-2">
            {message.media.map((media, mediaIndex) => (
              <div key={mediaIndex} className="relative">
                {media.type === "image" ? (
                  <div className="flex flex-col">
                    <img
                      src={media.url}
                      alt={media.name}
                      className="rounded-lg w-full h-24 object-cover cursor-pointer transition-transform hover:scale-105"
                      onClick={() => handleImageClick(media.url)}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="h-20 w-20 bg-gray-700 rounded-lg p-2 flex items-center justify-center">
                      <span className="text-xs text-center truncate">
                        {media.name}
                      </span>
                    </div>
                    <button
                      onClick={() => handleRemoveMedia(mediaIndex)}
                      className="w-full mt-1 bg-red-500 text-white rounded-lg p-1 text-xs flex items-center justify-center hover:bg-red-600 transition-colors duration-200"
                    >
                      <BsTrash size={12} className="mr-1" />
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Botones de Chatbot y WhatsApp */}
      <div className="flex items-center relative gap-[25px]">
        {/* Botón de WhatsApp */}
        <div className={`absolute right-0 transform transition-all duration-300 ${isOpen ? "translate-x-[-75px] opacity-100" : "translate-x-0 opacity-0 pointer-events-none"}`}>
          <button onClick={handleWhatsAppClick} aria-label="WhatsApp" className="bg-green-500 text-white p-3 sm:p-4 rounded-2xl shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 transform hover:scale-105 hover:rotate-6">
            <BsWhatsapp className="w-5 h-5 sm:w-6 sm:h-6" />
          </button>
        </div>

        {/* Botón de Chatbot */}
        <button
          onClick={toggleChatbot}
          aria-label="Abrir chatbot"
          className={`bg-[#10141C] text-gray-200 p-3 sm:p-4 rounded-2xl shadow-xl hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 transition-all duration-300 transform hover:scale-105 hover:-rotate-6 z-10 border-2 border-white ${isMobile && isOpen ? 'hidden' : ''}`}
        >
          {isOpen ? <IoMdClose className="w-5 h-5 sm:w-6 sm:h-6" /> : <BsRobot className="w-5 h-5 sm:w-6 sm:h-6" />}
        </button>
      </div>

      {/* Ventana del Chatbot */}
      {isOpen && (
        <div className={`fixed ${isMobile ? "top-0 left-0 w-full h-full" : "bottom-24 right-4"} ${isMobile ? "rounded-none" : "w-[350px] sm:w-[400px] h-[85vh] sm:h-[700px]"} bg-[#10141C] rounded-[20px] shadow-2xl transform transition-transform duration-300 ease-in-out border border-gray-700 overflow-hidden`}>
          <div className="flex flex-col h-full backdrop-blur-lg bg-[#10141C]/90">
            {/* Cabecera del Chatbot */}
            <div className="bg-[#10141C] text-gray-200 p-4 sm:p-6 rounded-t-[20px] flex items-center justify-between border-b border-gray-700">
              <div className="flex items-center space-x-3">
                <div className="bg-gray-700 p-2 rounded-xl rotate-12 hover:rotate-0 transition-all duration-300">
                  <BsRobot className="w-5 h-5 sm:w-6 sm:h-6 text-gray-200" />
                </div>
                <div>
                  <h2 className="text-lg sm:text-xl font-bold text-gray-200">Accarvip - Asesor Virtual</h2>
                  <div className="bg-gradient-to-r from-red-500 to-purple-500 text-xs px-3 py-1 rounded-full text-white font-medium inline-block">
                    Accarvip + Uniclick
                  </div>
                </div>
              </div>
              <button onClick={toggleChatbot} aria-label="Cerrar chatbot" className="text-gray-200 hover:text-gray-400 focus:outline-none transform hover:rotate-90 transition-transform duration-300">
                <IoMdClose className="w-6 h-6 sm:w-7 sm:h-7" />
              </button>
            </div>

            {/* Contenedor de Mensajes */}
            <div className="flex-1 overflow-y-auto p-4 sm:p-6 space-y-4 sm:space-y-6 bg-[#10141C] scrollbar scrollbar-thumb-gray-600 scrollbar-track-gray-700">
              {messages.map((message, index) => (
                <div key={index} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
                  <div className={`max-w-[90%] p-3 sm:p-4 rounded-[20px] transition-all duration-300 hover:scale-[1.02] ${message.sender === "user" ? "bg-gray-700 text-gray-200" : "bg-gray-800 text-gray-200 border border-gray-600"}`}>
                    {message.carData ? renderCarCard(message.carData) : renderMessage(message, index)}
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="flex justify-start">
                  <div className={`max-w-[90%] p-3 sm:p-4 rounded-[20px] shadow-lg transform transition-all duration-300 bg-gray-800 text-gray-200 border border-gray-600`}>
                    {typingMessage || "Daniel está preparando la mejor oferta..."}
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            {/* Pie de Página con Campo de Entrada */}
            <div className="flex flex-col bg-[#10141C]">
              {previewMedia.length > 0 && (
                <div className="p-3 border-t border-gray-700">
                  <div className="flex gap-2 overflow-x-auto pb-2">
                    {previewMedia.map((media, index) => (
                      <div key={index} className="relative min-w-[100px] group">
                        {media.type === "image" ? (
                          <div className="flex flex-col">
                            <img
                              src={media.url}
                              alt={media.name}
                              className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                              onClick={() => handleImageClick(media.url)}
                            />
                            <button
                              onClick={() => handleRemoveMedia(index)}
                              className="w-full mt-1 bg-red-500 text-white rounded-lg p-1 text-xs flex items-center justify-center hover:bg-red-600 transition-colors duration-200"
                            >
                              <BsTrash size={12} className="mr-1" />
                              Eliminar
                            </button>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <div className="h-20 w-20 bg-gray-700 rounded-lg p-2 flex items-center justify-center">
                              <span className="text-xs text-center truncate">{media.name}</span>
                            </div>
                            <button
                              onClick={() => handleRemoveMedia(index)}
                              className="w-full mt-1 bg-red-500 text-white rounded-lg p-1 text-xs flex items-center justify-center hover:bg-red-600 transition-colors duration-200"
                            >
                              <BsTrash size={12} className="mr-1" />
                              Eliminar
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <form onSubmit={handleSendMessage} className="p-3 sm:p-4 bg-[#10141C] border-t border-gray-700">
                <div className="flex items-center bg-gray-800 rounded-[20px] p-2 shadow-inner hover:shadow-md transition-shadow duration-300">
                  {/* Botón para Grabar Audio */}
                  <button 
                    type="button" 
                    onClick={handleAudioRecord} 
                    className={`p-2 rounded-[20px] ${isRecording ? "text-red-500 bg-red-700/50" : "text-gray-300"} hover:bg-gray-700 focus:outline-none transition-all duration-300 hover:rotate-12`} 
                    aria-label="Grabar audio"
                    disabled={isTyping || isLoading}
                  >
                    <BsMic className="w-4 h-4 sm:w-5 sm:h-5" />
                  </button>
                  {/* Botón para Subir Medios */}
                  <button 
                    type="button" 
                    onClick={handleMediaUpload} 
                    className="p-2 rounded-[20px] text-gray-300 hover:bg-gray-700 focus:outline-none transition-all duration-300 hover:-rotate-12" 
                    aria-label="Subir medios"
                    disabled={isTyping || isLoading}
                  >
                    <BsImage className="w-4 h-4 sm:w-5 sm:h-5" />
                  </button>
                  {/* Campo de Entrada de Texto */}
                  <input 
                    type="text" 
                    value={inputMessage} 
                    onChange={(e) => setInputMessage(e.target.value)} 
                    placeholder="Pregúntame sobre nuestros vehículos..." 
                    className="flex-1 px-3 sm:px-4 py-2 bg-transparent focus:outline-none text-gray-200 placeholder-gray-400 transition-all duration-300 focus:placeholder-transparent text-sm sm:text-base" 
                    aria-label="Campo de mensaje" 
                    disabled={isTyping || isLoading}
                  />
                  {/* Botón para Enviar o Detener la Escritura */}
                  {isTyping ? (
                    <button 
                      type="button" 
                      onClick={stopTyping} 
                      aria-label="Detener escritura"
                      className="p-2 rounded-[20px] bg-gray-600 text-white hover:bg-gray-700 focus:outline-none transition-all duration-300"
                      disabled={isLoading}
                    >
                      <div className="w-4 h-4 bg-white"></div>
                    </button>
                  ) : (
                    <button 
                      type="submit" 
                      aria-label="Enviar mensaje" 
                      className="p-2 rounded-[20px] text-gray-300 hover:bg-gray-700 focus:outline-none transition-all duration-300 hover:rotate-12" 
                      disabled={isLoading || isTranscribing || isTyping}
                    >
                      <IoSend className="w-4 h-4 sm:w-5 sm:h-5" />
                    </button>
                  )}
                </div>
              </form>
              {/* Pie de Página */}
              <div className="p-2 sm:p-3 bg-[#10141C] rounded-b-[20px] flex items-center justify-center space-x-2 text-xs sm:text-sm text-gray-400 border-t border-gray-700">
                <IoFlashSharp className="w-3 h-3 sm:w-4 sm:h-4 text-yellow-500 animate-pulse" />
                <span className="font-medium text-gray-300">Powered by</span>
                <span className="text-gray-400">Uniclick.io</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Imagen Ampliada */}
      {selectedImage && (
        <div className="fixed inset-0 z-[60] bg-black bg-opacity-90 flex items-center justify-center p-4" onClick={() => setSelectedImage(null)}>
          <div className="relative max-w-4xl max-h-[90vh] w-full">
            <img
              src={selectedImage}
              alt="Vista ampliada"
              className="w-full h-full object-contain rounded-lg"
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
            >
              <IoMdClose className="w-6 h-6" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotButton;
