// src/context/ModelsContext.js

import React, { createContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { parseUserInput, getCarInfo, allowedColors } from '../utils/searchUtils';

export const ModelsContext = createContext();

export const ModelsProvider = ({ children }) => {
  const [availableModels, setAvailableModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelsError, setModelsError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      setModelsLoading(true);
      try {
        const { data, error } = await supabase
          .from("VEHÍCULOS2_duplicate con datos")
          .select("model")
          .neq("model", "") // Asegurarse de que el modelo no esté vacío
          .order("model", { ascending: true });

        if (error) {
          console.error("Error al obtener modelos de Supabase:", error);
          setModelsError("No se pudo cargar la lista de modelos.");
          setAvailableModels([]);
        } else {
          // Extraer modelos únicos y ordenarlos por longitud descendente
          const uniqueModels = [...new Set(data.map(item => item.model.toLowerCase()))];
          uniqueModels.sort((a, b) => b.length - a.length); // Ordenar por longitud descendente
          setAvailableModels(uniqueModels);
          console.log("Modelos disponibles:", uniqueModels);
        }
      } catch (error) {
        console.error("Error al obtener modelos de Supabase:", error);
        setModelsError("No se pudo cargar la lista de modelos.");
        setAvailableModels([]);
      } finally {
        setModelsLoading(false);
      }
    };

    fetchModels();
  }, []);

  return (
    <ModelsContext.Provider value={{ availableModels, modelsLoading, modelsError }}>
      {children}
    </ModelsContext.Provider>
  );
};