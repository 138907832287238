// src/utils/searchUtils.js

import { supabase } from '../supabaseClient'; // Ajusta la ruta según tu estructura de carpetas

// Lista de colores permitidos
export const allowedColors = [
  "amarillo",
  "rojo",
  "azul",
  "negro",
  "blanco",
  "verde",
  "gris",
  "plateado",
  "morado",
  "naranja",
  "dorado",
  "marrón",
];

/**
 * Función para analizar la entrada del usuario y extraer parámetros de búsqueda.
 * @param {string} inputText - Entrada del usuario.
 * @param {Array} availableModels - Lista de modelos disponibles.
 * @param {string|null} lastModel - Último modelo consultado (opcional).
 * @returns {Object} - Parámetros extraídos: model, year, color, sortPrice, sortYear.
 */
export const parseUserInput = (inputText, availableModels, lastModel = null) => {
  const lowerText = inputText.toLowerCase().trim();
  console.log("Entrada del usuario para parseo:", lowerText);

  // Expresión regular para encontrar un número de 4 dígitos entre 1900 y 2100
  const yearMatch = lowerText.match(/(19|20)\d{2}/);
  const year = yearMatch ? parseInt(yearMatch[0]) : null;

  // Detectar colores
  let color = null;
  for (let c of allowedColors) {
    if (lowerText.includes(c)) {
      color = c;
      break;
    }
  }

  // Detectar preferencias de ordenamiento
  let sortPrice = null; // 'asc' para más barato, 'desc' para más caro
  let sortYear = null;  // 'desc' para más nuevo, 'asc' para más viejo

  if (lowerText.includes("más barato") || lowerText.includes("barato")) {
    sortPrice = 'asc';
  } else if (lowerText.includes("más caro") || lowerText.includes("caro")) {
    sortPrice = 'desc';
  }

  if (lowerText.includes("más nuevo") || lowerText.includes("nuevo")) {
    sortYear = 'desc';
  } else if (lowerText.includes("más viejo") || lowerText.includes("viejo")) {
    sortYear = 'asc';
  }

  // Detectar modelos utilizando la lista dinámica de modelos
  let modelsDetected = [];
  availableModels.forEach(carModel => {
    if (lowerText.includes(carModel.toLowerCase())) {
      modelsDetected.push(carModel.toLowerCase());
    }
  });

  // Priorizar el último modelo mencionado en caso de múltiples detecciones
  let model = null;
  if (modelsDetected.length > 0) {
    model = modelsDetected[modelsDetected.length - 1];
    console.log("Modelo detectado en la entrada:", model);
  }

  // Manejo de referencias contextuales
  // Si no se encuentra un modelo específico, intentar usar el último modelo consultado
  if (!model && lastModel) {
    model = lastModel.toLowerCase();
    console.log("Usando lastModel como referencia:", model);
  }

  // Eliminar palabras comunes, colores y el año del texto para obtener el modelo (si aún no se ha identificado)
  if (!model) {
    let modelText = lowerText;
    modelText = modelText.replace(/(busca(me)?|un|una|el|la|por|favor|quiero|me|mostrar|muéstrame|del|de|para|coche|auto|vehículo|ahora|quieres)/g, '');
    if (year) {
      modelText = modelText.replace(year.toString(), '');
    }
    if (color) {
      modelText = modelText.replace(color, '');
    }
    if (sortPrice) {
      modelText = modelText.replace(/barato|barato|caro|caro/g, '');
    }
    if (sortYear) {
      modelText = modelText.replace(/nuevo|nuevo|viejo|viejo/g, '');
    }

    // Eliminar espacios adicionales
    modelText = modelText.trim();

    // Asignar modelo si el texto resultante no está vacío
    model = modelText.length > 0 ? modelText : null;
    console.log("Modelo extraído después de limpieza:", model);
  }

  return { model, year, color, sortPrice, sortYear };
};

/**
 * Función para obtener información de vehículos desde Supabase basado en los parámetros de búsqueda.
 * @param {Object} params - Parámetros de búsqueda: model, year, color, sortPrice, sortYear, maxPrice, minYear, maxYear, minKilometraje, maxKilometraje, combustible.
 * @param {number} page - Número de página para la paginación.
 * @param {number} pageSize - Tamaño de página para la paginación.
 * @returns {Object} - { data: Array de vehículos, total: Número total de vehículos }
 */
export const getCarInfo = async (
  {
    model = null,
    year = null,
    color = null,
    sortPrice = null,
    sortYear = null,
    maxPrice = null,
    minYear = null,
    maxYear = null,
    minKilometraje = null,
    maxKilometraje = null,
    combustible = null,
  },
  page = 1,
  pageSize = 20
) => {
  try {
    // Iniciar la consulta en Supabase
    let query = supabase.from("VEHÍCULOS2_duplicate con datos").select("*");

    // Aplicar filtros
    if (model) {
      query = query.ilike("model", `%${model}%`);
    }
    if (year) {
      query = query.eq("year", year);
    }
    if (color) {
      query = query.ilike("color", `%${color}%`);
    }
    if (combustible) {
      query = query.eq("combustible", combustible); // Filtrar por combustible
    }
    if (maxPrice) {
      query = query.lte("precio", parseInt(maxPrice));
    }
    if (minYear) {
      query = query.gte("year", parseInt(minYear));
    }
    if (maxYear) {
      query = query.lte("year", parseInt(maxYear));
    }
    if (minKilometraje) {
      query = query.gte("kilometraje", parseInt(minKilometraje));
    }
    if (maxKilometraje) {
      query = query.lte("kilometraje", parseInt(maxKilometraje));
    }

    // Ordenar resultados
    if (sortPrice) {
      query = query.order("precio", { ascending: sortPrice === "asc" });
    }
    if (sortYear) {
      query = query.order("year", { ascending: sortYear === "asc" });
    }

    // Paginación
    const from = (page - 1) * pageSize;
    const to = from + pageSize - 1;
    query = query.range(from, to);

    // Ejecutar consulta
    const { data, count, error } = await query;

    if (error) {
      console.error("Error al obtener vehículos de Supabase:", error);
      return { data: [], total: 0 };
    }

    return { data, total: count };
  } catch (error) {
    console.error("Error inesperado en getCarInfo:", error);
    return { data: [], total: 0 };
  }
};
