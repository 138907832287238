import React from 'react';
import ChatbotButton from './components/ChatbotButton';
import SearchComponent from './components/SearchComponent';
import { ModelsProvider } from './context/ModelsContext'; // Importar el Provider

function App() {
  return (
    <ModelsProvider>
      <div className="App">
        <SearchComponent />
        <ChatbotButton />
      </div>
    </ModelsProvider>
  );
}

export default App;